<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="用户姓名" prop="userId">
							<el-select
								v-model="formData.userId"
								filterable
								placeholder="请选择用户"
								style="width: 100%"
								:disabled="formType === 'update'"
								@change="onUserChange"
							>
								<el-option
									v-for="item in userOptions"
									:key="item.id"
									:label="item.realName"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="登录账号" prop="mobile">
							<el-input v-model="formData.mobile" disabled placeholder="请选择用户" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="所属公司" prop="company">
							<el-input
								v-model="formData.company"
								disabled
								placeholder="请选择用户"
								type="textarea"
								lines="3"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="数据权限" prop="type">
							<el-select
								v-model="formData.type"
								filterable
								placeholder="请选择权限值"
								style="width: 100%"
								:disabled="formType === 'update'"
								@change="onAuthChange"
							>
								<el-option
									v-for="item in authTypeOptions"
									:key="item.code"
									:label="item.name"
									:value="item.code"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formData.type == 10">
					<el-col :span="18">
						<el-form-item label="权限值" prop="permissionValueArr">
							<el-select
								v-model="formData.permissionValueArr"
								filterable
								multiple
								placeholder="请选择权限值"
								style="width: 100%"
							>
								<el-option
									v-for="item in companyOptions"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row v-if="formData.type == 20">
					<el-col :span="18">
						<el-form-item label="城市" prop="permissionValueArr">
							<el-cascader
								style="width: 100%"
								:props="{ multiple: true, emitPath: false }"
								collapse-tags
								v-model="formData.permissionValueArr"
								:options="options"
								filterable
							></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits } from 'vue'
import { rules } from '../config'
import { useDict } from '@/utils/hooks.js'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')

const formInitValue = {
	type: '',
	company: '',
	mobile: '',
	permissionValueArr: [],
	userId: '',
}

const options = ref([])
const companyOptions = ref([])
const formData = reactive({ ...formInitValue })
const formRule = rules
const userOptions = ref([])
const authTypeOptions = ref([])

const formType = ref('create')
let waitUpdateId = ''
function onAuthChange(code) {
	console.log('code', code)
}
async function fetchDict() {
	const dict = await useDict('user_data_permission_type')
	if (dict)
		authTypeOptions.value = dict.user_data_permission_type.map(item => ({
			...item,
			code: item.code * 1,
		}))
}
function initCompany() {
	formData.permissionValueArr = ''
	Request({
		url: `suf4-user-service/sysRepairStorage/select/${formData.type || 10}`,
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				companyOptions.value = res.data
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}
function initPermissionList() {
	formData.permissionValueArr = ''
	Request({
		url: `/suf4-system-service/region/tree`,
		method: 'POST',
		data: {},
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				options.value = res.data.map(item => {
					item.label = item.name
					item.value = item.code
					item.children = item.children.map(child => ({
						...child,
						label: child.name,
						value: child.code,
						children: [],
					}))
					return item
				})
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function initEnums() {
	return Request({
		url: 'suf4-user-service/userInfo/paginate/select',
		method: 'POST',
		data: {
			page: {
				current: 1,
				size: 9999,
			},
		},
	})
		.then(res => {
			if (res.code === 200) {
				console.log('用户列表获取成功 ==>', res.data)
				userOptions.value = res.data.records
			} else {
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	initPermissionList()
	initCompany()
	formType.value = 'create'
	dialogTitle.value = '新增数据权限'
	initEnums()
	isShowDialog.value = true
}

async function update(item) {
	formType.value = 'update'
	dialogTitle.value = '编辑数据权限'
	waitUpdateId = item.id
	Object.assign(formData, item)
	initPermissionList()
	initCompany()
	formData.permissionValueArr = item.permissionValue
		.split(',')
		.map(code => (item.type == 20 ? code * 1 : code))
	console.log('formData.permissionValueArr', item.permissionValue)
	await initEnums()
	onUserChange(item.userId)
	isShowDialog.value = true
}

function deleteItem(id) {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {
			return Request({
				url: `suf4-user-service/userDataPermission/removeById/${id}`,
				method: 'GET',
			})
		})
		.then(res => {
			if (res.code === 200) {
				$message.success(`删除成功!`)
				onClose()
				emit('refresh')
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(e => {
			$message.error(e.message || '删除失败!')
		})
}

function onUserChange(e) {
	const userInfo = userOptions.value.find(user => user.id === e)
	console.log('选择用户变化 ==>', e, userInfo)
	if (userInfo.id) {
		formData.company = userInfo.companyList.map(item => item.companyName).join(',')
		formData.mobile = userInfo.mobile
	}
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType.value === 'create') {
			requestUrl = `suf4-user-service/userDataPermission/create`
			delete params.id
			params.permissionValue = params.permissionValueArr ? params.permissionValueArr.join(',') : ''
		} else {
			requestUrl = `suf4-user-service/userDataPermission/update`
			params.id = waitUpdateId
			params.permissionValue = params.permissionValueArr ? params.permissionValueArr.join(',') : ''
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}
fetchDict()
defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
