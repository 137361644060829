import { phoneReg } from '@/utils/regUtil'

export const columns = [
	{
		prop: 'type',
		label: '数据权限',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '维修厂' : '城市'
		},
	},
	{
		prop: 'realName',
		label: '用户姓名',
	},
	{
		prop: 'phone',
		label: '手机号',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.companyName).join(',')
		},
	},
	{
		prop: 'roleList',
		label: '所属角色',
		width: 300,
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.roleName).join(',')
		},
	},
	{
		prop: 'auth',
		label: '权限值',
	},
	/* {
		prop: 'rolePermissions',
		label: '联系人',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	}, */
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '用户姓名',
			attr: 'realName',
			type: 'search',
			placeholder: '请输入用户姓名',
			clearable: true,
		},
		{
			label: '手机号',
			attr: 'phone',
			type: 'search',
			placeholder: '请输入手机号',
			clearable: true,
		},
		{
			attr: 'typeList',
			label: '数据权限',
			type: 'multi-select',
			placeholder: '选择数据权限',
			options: [
				{ name: '维修厂', code: 10 },
				// { name: '库存地', code: 20 },
			],
			dictKey: 'user_data_permission_type',
		},
		{
			attr: 'status',
			label: '合作状态',
			type: 'select',
			placeholder: '请选择合作状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	userId: [{ required: true, message: '请选择用户', trigger: 'blur' }],
	type: [{ required: true, message: '请选择数据权限', trigger: 'blur' }],
	permissionValueArr: [{ required: true, message: '请选择城市', trigger: 'blur' }],
}
